import React from "react";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import memoize from "memoize-one";

class InputPasswordM extends React.Component {
  state = {
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
    is_error: false,
  };

  handleChange = prop => event => {
    this.setState({ [prop]: event.target.value });
    if (prop == "password") {
      this.props.setValue(event.target.value);
    }
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  ifmycomponentupdate = memoize(text => {
    // console.log(text);
    if (text && text.length >= 6) {
      this.setState({
        is_error: false,
      });
    } else {
      this.setState({
        is_error: true,
      });
    }
    this.props.setValue(text);
  });

  render() {
    this.ifmycomponentupdate(this.props.value);
    return (
      <TextField
        label={this.props.label}
        style={this.props.style}
        id="adornment-password"
        placeholder="password"
        variant="outlined"
        size="small"
        error={this.state.is_error}
        type={this.state.showPassword ? "text" : "password"}
        value={this.props.value}
        onChange={this.handleChange("password")}
        helperText={
          !this.state.is_error
            ? undefined
            : "Please Input Password Min 6 Character"
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={this.handleClickShowPassword}
                onMouseDown={this.handleMouseDownPassword}
                size="small"
              >
                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
}

export default InputPasswordM;
