import React from "react";
import { withRouter } from "react-router-dom";
import AddGateway from "./AddGateway";
import moment from "moment";
import { Grid, Modal, Backdrop, Fade, makeStyles } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import api from "../../URL/axiosConfig";
// components
import PageTitle from "../../components/PageTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "80%",
  },
  paper2: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "40%",
  },
}));

function Gateway(props) {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [head, setHead] = React.useState([]);
  const [isBusy, setBusy] = React.useState(true);
  React.useEffect(() => {
    setBusy(false);
    const fetchData = async () => {
      const res = await api("/gateway");
      const datas = res.data.raw ? res.data.raw : [];
      console.log(datas);
      const resultArray = datas.map(key => ({
        gateway_name: key.gateway_name,
        gateway_id: key.gateway_id,
        status: key.status == true ? "active" : "inactive",
        location: key.location,
        // companyName: key.weiots_company?.company_name,
        // installationDate: key.installationDate
        //   ? moment(key.installationDate).format("YYYY-MM-DD HH:mm:ss")
        //   : null,
      }));
      var bodies = [];
      var bodyItems = [];
      resultArray.map(val => {
        Object.keys(val).forEach(function(val2) {
          bodyItems.push(val[val2]);
          console.log(bodyItems);
        });
        bodies.push(bodyItems);
        bodyItems = [];
        console.log(bodies);
        return setData(bodies);
      });
      console.log(datas);
      var headItems = ["Gateway Name", "Gateway Id", "Status", "Location"];
      setHead(headItems);
    };
    fetchData();
  }, []);
  const classes = useStyles();
  const handleModal = () => {
    setOpen(!open);
    console.log(open);
  };
  return (
    <>
      <PageTitle title="Gateway" button="Add Gateway" modal={handleModal} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            data={data}
            columns={head}
            options={{
              filter: true,
              selectableRows: false,
              filterType: "dropdown",
              responsive: "stacked",
              rowsPerPage: 10,
              textLabels: {
                body: {
                  noMatch: isBusy ? (
                    <div>
                      <CircularProgress disableShrink />
                    </div>
                  ) : (
                    "Sorry, there is no matching data to display"
                  ),
                },
              },
              onSearchOpen: () => {
                setBusy(false);
              },
              onsearchClose: () => {
                setBusy(true);
              },
            }}
          />
        </Grid>
        {/* <Grid item xs={12}>
              <Widget title="Material-UI Table" upperTitle noBodyPadding>
                <Table data={mock.table} />
              </Widget>
            </Grid> */}
      </Grid>

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper2}>
            <AddGateway onclose={handleModal} />
          </div>
        </Fade>
      </Modal>
    </>
  );
}

export default withRouter(Gateway);
