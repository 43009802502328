import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import AddNodeType from "./AddNodeType";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid, Modal, Backdrop, Fade, makeStyles } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import api from "../../URL/axiosConfig";
// components
import PageTitle from "../../components/PageTitle";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "80%",
  },
  paper2: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "40%",
  },
}));

function NodeType(props) {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [head, setHead] = React.useState([]);
  const [isBusy, setBusy] = React.useState(true);
  React.useEffect(() => {
    setBusy(false);
    const fetchData = async () => {
      const res = await api("/list/type");
      console.log("data", res.data);
      const datas = res.data.list_type ? res.data.list_type : [];
      const resultArray = datas.map(key => ({
        name: key.type_name,
        active: key.active == true ? "active" : "inactive",
      }));
      var bodies = [];
      var bodyItems = [];
      resultArray.map(val => {
        Object.keys(val).forEach(function(val2) {
          bodyItems.push(val[val2]);
          console.log(bodyItems);
        });
        bodies.push(bodyItems);
        bodyItems = [];
        console.log(bodies);
        return setData(bodies);
      });
      console.log(datas);
      var headItems = ["Type Name", "active"];
      setHead(headItems);
      //setData(resultArray)
    };
    fetchData();
  }, []);
  const classes = useStyles();
  const handleModal = () => {
    setOpen(!open);
    console.log(open);
  };
  return (
    <>
      <PageTitle title="Node Type" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            data={data}
            columns={head}
            options={{
              filter: true,
              selectableRows: false,
              filterType: "dropdown",
              responsive: "stacked",
              rowsPerPage: 10,
              textLabels: {
                body: {
                  noMatch: isBusy ? (
                    <div>
                      <CircularProgress disableShrink />
                    </div>
                  ) : (
                    "Sorry, there is no matching data to display"
                  ),
                },
              },
              onSearchOpen: () => {
                setBusy(false);
              },
              onsearchClose: () => {
                setBusy(true);
              },
            }}
          />
        </Grid>
      </Grid>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper2}>
            <AddNodeType onclose={handleModal} />
          </div>
        </Fade>
      </Modal>
    </>
  );
}

export default withRouter(NodeType);
