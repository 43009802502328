import React from "react";
import {
  Button,
  makeStyles,
  Card,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import api from "../../URL/axiosConfig";

import { Close as AddIcon } from "@material-ui/icons";

const Cards = props => {
  const [header, setHeader] = React.useState("");
  const [ListNode, setListNode] = React.useState([]);
  const [detail, setDetail] = React.useState("");
  const [brand, setBrand] = React.useState("");
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  const [fields, setFields] = React.useState([{ description: null }]);

  function handleChange(i, event) {
    const values = [...fields];
    values[i].description = event.target.value;
    setFields(values);
  }

  function handleAdd() {
    const values = [...fields];
    values.push({ description: null });
    setFields(values);
  }

  function handleRemove(i) {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
  }

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);

    api("/list/node?typeId=13")
      .then(res => {
        res = JSON.parse(JSON.stringify(res));
        setListNode(res.data.messages);
      })
      .catch(e => {
        console.log(e);
      });
  }, []);
  const addCompany = e => {
    e.preventDefault();
    api({
      method: "POST",
      url: "/rtu/appname",
      data: {
        header: header,
        detail: detail,
        brand: brand,
        billing: fields,
        is_water_level: true,
      },
    })
      .then(res => {
        console.log(res.data);
        if (res.data.responseCode === 200) {
          alert(res.data.messages);
          window.location.reload();
        } else {
          alert(res.data.messages);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };
  console.log(fields);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "space-around",
      }}
    >
      <Typography variant="h6">Add Appname for RTU Water Level</Typography>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "",
            flexWrap: "wrap",
            justifyContent: "space-around",
            width: "80%",
          }}
        >
          <FormControl
            style={{ marginTop: "5%", width: "100%" }}
            size="small"
            variant="outlined"
          >
            <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple">
              devEUI
            </InputLabel>
            <Select
              native
              value={header}
              onChange={e => setHeader(e.target.value)}
              labelWidth={labelWidth}
              inputProps={{
                name: "age",
                id: "outlined-age-native-simple",
              }}
            >
              <option value="" />
              {ListNode.map(val => {
                return (
                  <option key={val.id} value={val.devEUI}>
                    {val.devEUI}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          {/* <TextField onChange={(e) => setHeader(e.target.value)} style={{marginTop:'5%',width:'100%'}} variant='outlined' size='small' label='Header'/> */}
          <TextField
            onChange={e => setDetail(e.target.value)}
            style={{ marginTop: "5%", width: "100%" }}
            variant="outlined"
            size="small"
            label="Display Name"
          />
          <TextField
            onChange={e => setBrand(e.target.value)}
            style={{ marginTop: "5%", width: "100%" }}
            variant="outlined"
            size="small"
            label="App Name"
          />
        </div>
      </div>
      <div
        style={{
          paddingLeft: "10%",
          marginTop: "10%",
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: "60%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={props.onclose}
            variant="outlined"
            size="small"
            color="default"
          >
            cancel
          </Button>
          <Button
            onClick={addCompany}
            variant="outlined"
            size="small"
            style={{
              marginLeft: "3%",
              backgroundColor: "#429C46",
              color: "white",
            }}
          >
            save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Cards;
