import React from "react";
import { Redirect} from "react-router-dom";
import { Button,Switch,FormControlLabel, Typography, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import api from "../../URL/axiosConfig";

const Cards=(props)=>{
    const [typeName, setTypeName] = React.useState('')
    const [active, setActive] = React.useState(true)

    React.useEffect(() => {
      //setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const addCompany = e => {
        e.preventDefault();
        api({
          method: "POST",
          url: "/register/type",
          data: [{
            type_name: typeName,
            active: active,
          }]
        })
          .then(res => {
            console.log(res.data);
            if (res.data.responseCode === 200) {
              alert(res.data.messages)
              window.location.reload()
            }  else {
              alert(res.data.messages)
            }
          })
          .catch(e => {
            console.log(e);
          });
    
      }

      const toggleChecked = () => {
        setActive(!active);
    };
    return(
        <div style={{display:'flex', flexDirection:'column', flexWrap:'wrap', justifyContent:'space-around'}}>
            <Typography variant='h6'>Register Node Type</Typography>

            <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'space-around'}}>
            <div  style={{display:'flex', flexDirection:'', flexWrap:'wrap', justifyContent:'start', width:'80%'}}>
            <FormControlLabel
              control={<Switch checked={active} onChange={toggleChecked} />}
              label={active ? "active" : "inactive"}
            />
            <TextField onChange={(e) => setTypeName(e.target.value)} style={{marginTop:'5%',width:'100%'}} variant='outlined' size='small' label='Type Name'/>
            </div>
            </div>
            <div style={{marginTop:'5%',width:'100%', display:'flex', flexWrap:'wrap', justifyContent:'flex-end'}}>
                <Button onClick={props.onclose} variant='outlined' size='small' color='default'>cancel</Button>
                <Button onClick={addCompany} variant='outlined' size='small' style={{marginLeft:'3%',backgroundColor:'#429C46', color:'white'}}>save</Button>
            </div>
        </div>
    )
}

export default Cards