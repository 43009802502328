import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid, Modal, Backdrop, Fade, makeStyles } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import api from "../../URL/axiosConfig";
// components
import PageTitle from "../../components/PageTitle";
import moment from "moment";
import EnterNewLicense from "../../components/EnterNewLicense/EnterNewLicense";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "80%",
  },
  paper2: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "40%",
  },
}));

const WeCurrencyUsd = (value = Number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
};

const WeCurrencyIdr = (value = Number) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(value);
};

function Licenses(props) {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [head, setHead] = React.useState([]);
  const [isBusy, setBusy] = React.useState(true);
  React.useEffect(() => {
    setBusy(false);
    const fetchData = async () => {
      const res = await api("/v2/list/license");
      console.log("data", res.data);
      const datas = res.data.data ? res.data.data : [];
      const resultArray = datas.map(key => ({
        license_key: key.license_key,
        start_date: moment(key.start_date).format("DD-MM-YYYY"),
        expired_date: moment(key.expired_date).format("DD-MM-YYYY"),
      }));
      var bodies = [];
      var bodyItems = [];
      resultArray.map(val => {
        Object.keys(val).forEach(function(val2) {
          bodyItems.push(val[val2]);
          console.log(bodyItems);
        });
        bodies.push(bodyItems);
        bodyItems = [];
        console.log(bodies);
        return setData(bodies);
      });
      console.log(datas);
      var headItems = ["License Key", "Start Date", "Expired Date"];
      setHead(headItems);
      //setData(resultArray)
    };
    fetchData();
  }, []);
  const classes = useStyles();
  const handleModal = () => {
    setOpen(!open);
    console.log(open);
  };
  return (
    <>
      <PageTitle title="SaaS License" />
      <Grid container spacing={4}>
        <Grid item xs={8}>
          <EnterNewLicense checklicense={true} />
        </Grid>
        <Grid item xs={8}>
          <MUIDataTable
            data={data}
            columns={head}
            options={{
              filter: true,
              selectableRows: false,
              filterType: "dropdown",
              responsive: "stacked",
              rowsPerPage: 10,
              textLabels: {
                body: {
                  noMatch: isBusy ? (
                    <div>
                      <CircularProgress disableShrink />
                    </div>
                  ) : (
                    "Sorry, there is no matching data to display"
                  ),
                },
              },
              onSearchOpen: () => {
                setBusy(false);
              },
              onsearchClose: () => {
                setBusy(true);
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default withRouter(Licenses);
