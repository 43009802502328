import { TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import moment from "moment";
import React from "react";
import api from "../../URL/axiosConfig";

const EnterNewLicense = ({ style, checklicense }) => {
  const [licensekey, setLicenseKey] = React.useState("");
  const [message, setMessage] = React.useState("");

  React.useEffect(() => {
    api({
      url: "/v2/cek/license/" + licensekey,
    })
      .then(ok => {
        console.log(ok.data);
        setMessage(
          "Key valid until " + moment(ok.data.data.expired_date).format("LL"),
        );
      })
      .catch(err => {
        if (err.response.data.responseCode == 404) {
          setMessage(err.response.data.message);
        }
        console.log(err);
      });
  }, [licensekey]);

  const SubmitLicense = () => {
    api({
      url: "/v2/submit/license/" + licensekey,
      method: "POST",
    })
      .then(ok => {
        alert("Success Submit License");
        window.localStorage.setItem("is_expired", false);
        window.location.reload();
      })
      .catch(err => {
        alert("Invalid key");
        console.log(err);
      });
  };

  return (
    <Grid container spacing={2} style={style}>
      <Grid item xs={12}>
        <TextField
          required
          onChange={e => setLicenseKey(e.target.value)}
          style={{ width: "70%" }}
          variant="outlined"
          size="small"
          label="Enter License Key"
          type="text"
          value={licensekey}
        />
        <Button
          onClick={SubmitLicense}
          variant="outlined"
          size="large"
          style={{
            marginLeft: "3%",
            backgroundColor: "#429C46",
            color: "white",
          }}
        >
          Submit
        </Button>
      </Grid>
      {checklicense && licensekey != "" ? (
        <Grid item xs={12}>
          <div style={{ padding: "24px" }}>{message}</div>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default EnterNewLicense;
