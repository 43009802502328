import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";

// components
import PageTitle from "../../components/PageTitle";
import EditProfile from "../Users/EditProfile";

function Profile(props) {
  return (
    <>
      <PageTitle title="Profile" />
      <Grid container spacing={4}>
        <Grid item xs={10} xl={6}>
          <EditProfile />
        </Grid>
      </Grid>
    </>
  );
}

export default withRouter(Profile);
