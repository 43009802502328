import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Company from "../../pages/company";
import node from "../../pages/node";
import nodeType from "../../pages/nodeType";
import middleware from "../../pages/middleware";
import mappingCompany from "../../pages/mappingCompany";
import rtu from "../../pages/rtu";
import RtuWaterLevel from "../../pages/rtuwaterlevel";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Tables from "../../pages/tables";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";
import Gateway from "../../pages/gateways";
import SaaSBilling from "../../pages/SaasBilling/SaaSBilling";
import Users from "../../pages/Users/Users";
// context
import { useLayoutState } from "../../context/LayoutContext";
import Profile from "../../pages/Profile/Profile";
import License from "../../pages/License/License";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path="/app/gateway" component={Gateway} />
            <Route path="/app/dashboard" component={Dashboard} />
            <Route path="/app/typography" component={Typography} />
            <Route path="/app/tables" component={Tables} />
            <Route path="/app/company" component={Company} />
            <Route path="/app/notifications" component={Notifications} />
            <Route path="/app/mappingCompany" component={mappingCompany} />
            <Route path="/app/middleware" component={middleware} />
            <Route path="/app/node" component={node} />
            <Route path="/app/type-node" component={nodeType} />
            <Route path="/app/rtu" component={rtu} />
            <Route path="/app/water-level" component={RtuWaterLevel} />
            <Route
              exact
              path="/app/ui"
              render={() => <Redirect to="/app/ui/icons" />}
            />
            <Route path="/app/ui/maps" component={Maps} />
            <Route path="/app/ui/icons" component={Icons} />
            <Route path="/app/ui/charts" component={Charts} />
            <Route path="/app/saas-billing" component={SaaSBilling} />
            <Route path="/app/users" component={Users} />
            <Route path="/app/profile" component={Profile} />
            <Route path="/app/license" component={License} />
          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
