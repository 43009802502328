import React from "react";
import { Button, TextField } from "@material-ui/core";
import api from "../../URL/axiosConfig";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import InputPasswordM from "./InputPassowrdM";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const EditProfile = props => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const inputLabel = React.useRef(null);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [RePassword, setRePassword] = React.useState("");

  const [companyName, setCompanyName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const [saveDisable, setSaveDisable] = React.useState(true);
  React.useEffect(() => {
    if (email == "" || companyName == "" || phone == "" || address == "") {
      setSaveDisable(true);
    } else setSaveDisable(false);
  }, [email, password, companyName, phone, address]);

  const EditProfile = e => {
    e.preventDefault();
    setLoading(true);
    api({
      method: "PATCH",
      url: "/v2/profile",
      data: {
        email: email,
        name: companyName,
        phone: phone,
        address: address,
      },
    })
      .then(res => {
        setLoading(false);
        if (res.data.responseCode === 200) {
          alert("Success Edit User");
          //   window.location.reload();
          window.location.replace("/#/app/company");
        } else {
          alert(res.data.messages);
        }
      })
      .catch(e => {
        console.log(e);
        alert(e.response.data.messages);
      });
  };

  React.useState(async () => {
    setLoading(true);
    api("/v2/profile")
      .then(ok => {
        setLoading(false);
        console.log(ok.data);
        setCompanyName(ok.data.data.name);
        setEmail(ok.data.data.email);
        setPhone(ok.data.data.phone);
        setAddress(ok.data.data.address);
      })
      .catch(err => console.log(err));
  }, []);

  const handleChangePw = () => {
    if (password.length < 6 || password !== RePassword) {
      if (password !== RePassword) {
        alert("password doesn't match the requirements");
      } else {
        alert("password doesn't match the requirements");
      }
    } else {
      setLoading(true);
      api({
        url: `/v2/profile`,
        method: "PUT",
        data: {
          password: password,
        },
      })
        .then(ok => {
          setLoading(false);
          alert("Success Change Password");
          //   setPassword("");
          window.location.replace("/#/app/company");
          //   setRePassword("");
        })
        .catch(err => {
          setLoading(false);

          alert(err.response.data.messages);
        });
    }
  };

  return (
    <div>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Profile Info" {...a11yProps(0)} />
          <Tab label="Password" {...a11yProps(1)} />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          {/* <Typography variant="h6">Edit Profile</Typography> */}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-around",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "",
                flexWrap: "wrap",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <TextField
                required
                error={companyName == "" ? true : false}
                onChange={e => setCompanyName(e.target.value)}
                style={{ marginTop: "5%", width: "100%" }}
                variant="outlined"
                size="small"
                label="Name"
                value={companyName}
              />
              <TextField
                required
                error={email == "" ? true : false}
                onChange={e => setEmail(e.target.value)}
                style={{ marginTop: "5%", width: "100%" }}
                variant="outlined"
                size="small"
                label="Email"
                type="email"
                disabled
                value={email}
              />

              <TextField
                required
                error={phone == "" ? true : false}
                onChange={e => setPhone(e.target.value)}
                style={{ marginTop: "5%", width: "100%" }}
                variant="outlined"
                size="small"
                label="Phone"
                value={phone}
              />

              <TextField
                required
                error={address == "" ? true : false}
                onChange={e => setAddress(e.target.value)}
                style={{ marginTop: "5%", width: "100%" }}
                variant="outlined"
                size="small"
                label="Address"
                value={address}
              />
            </div>
          </div>
          <div
            style={{
              marginTop: "5%",
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
            }}
          >
            <Button
              disabled={saveDisable}
              onClick={EditProfile}
              variant="outlined"
              size="small"
              style={{
                marginLeft: "3%",
                backgroundColor: "#429C46",
                color: "white",
              }}
            >
              save
            </Button>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            justifyContent: "space-around",
          }}
        >
          {/* <Typography variant="h6">Edit Profile</Typography> */}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-around",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "",
                flexWrap: "wrap",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <InputPasswordM
                style={{ width: "100%", marginTop: "5%" }}
                setValue={setPassword}
                label="Password"
                value={password}
              />
              <InputPasswordM
                style={{ width: "100%", marginTop: "5%" }}
                setValue={setRePassword}
                label="Re Enter Password"
                value={RePassword}
              />
            </div>
          </div>
          <div
            style={{
              marginTop: "5%",
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
            }}
          >
            <Button
              disabled={saveDisable}
              onClick={handleChangePw}
              variant="outlined"
              size="small"
              style={{
                marginLeft: "3%",
                backgroundColor: "#429C46",
                color: "white",
              }}
            >
              save
            </Button>
          </div>
        </div>
      </TabPanel>
    </div>
  );
};

export default EditProfile;
