import React, { useState } from "react";
import { Grid, Typography, Button, TextField, Fade } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { URL } from "../../URL/index";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
// styles
import useStyles from "./styles";

// logo
import logo from "../../assets/weiots.png";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// context
import { useUserDispatch } from "../../context/UserContext";
import moment from "moment";
import EnterNewLicense from "../../components/EnterNewLicense/EnterNewLicense";

function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();

  // local
  var [error, setError] = useState(null);
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  const [open, setOpen] = useState(false);

  const handleLogin = e => {
    e.preventDefault();
    setError(false);
    console.log(loginValue, passwordValue);
    axios({
      method: "POST",
      url: URL + "/login",
      data: {
        email: loginValue,
        password: passwordValue,
      },
    })
      .then(res => {
        console.log(res.data);
        if (res.data.responseCode === 200) {
          localStorage.setItem("token_weiots_cms", res.data.token);
          userDispatch({ type: "LOGIN_SUCCESS" });
          // window.location.replace("#/app/company");
          window.location.reload();
          window.localStorage.setItem("alert", true);
          window.localStorage.setItem("date", moment().format("YYYY-MM-DD"));
        } else {
          alert("Ada kesalahan jaringan");
        }
      })
      .catch(e => {
        // alert(e.response.data.data.message);
        if (e.response.data.data.is_expired) {
          setOpen(true);
          window.localStorage.setItem(
            "is_expired",
            e.response.data.data.is_expired,
          );
        }
        console.log(e.response);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (localStorage.getItem("token_weiots_cms")) {
      window.location.replace("#/app/company");
    }
  }, []);

  React.useEffect(() => {
    if (window.localStorage.getItem("is_expired") == "true") {
      setOpen(true);
    }
  }, []);

  return (
    <Grid container className={classes.container}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Reminder"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your subscription has expired. Please enter a new license key or
            contact our sales to acquire one.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <EnterNewLicense style={{ margin: "12px 0" }} />
        </DialogActions>
      </Dialog>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Typography variant="h1" className={classes.greeting}>
            Login
          </Typography>
          <Fade in={error}>
            <Typography color="secondary" className={classes.errorMessage}>
              Something is wrong with your login or password :(
            </Typography>
          </Fade>
          <TextField
            id="email"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={loginValue}
            onChange={e => setLoginValue(e.target.value)}
            margin="normal"
            placeholder="Email Adress"
            type="email"
            fullWidth
          />
          <TextField
            id="password"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            value={passwordValue}
            onChange={e => setPasswordValue(e.target.value)}
            margin="normal"
            placeholder="Password"
            type="password"
            fullWidth
          />
          <div className={classes.formButtons}>
            <Button
              onClick={handleLogin}
              variant="contained"
              color="primary"
              size="large"
            >
              Login
            </Button>
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
