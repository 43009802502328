import React from "react";
import { Button, Typography, TextField } from "@material-ui/core";
import api from "../../URL/axiosConfig";

const Cards = props => {
  const inputLabel = React.useRef(null);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [picName, setPicName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [handphone, setHandphone] = React.useState("");
  const [urlApi, setApi] = React.useState("");
  const [saveDisable, setSaveDisable] = React.useState(true);
  React.useEffect(() => {
    if (
      email == "" ||
      password == "" ||
      companyName == "" ||
      phone == "" ||
      address == ""
    ) {
      setSaveDisable(true);
    } else setSaveDisable(false);
  }, [
    email,
    password,
    companyName,
    picName,
    phone,
    address,
    handphone,
    urlApi,
  ]);

  const addCompany = e => {
    e.preventDefault();
    api({
      method: "POST",
      url: "/v2/add/user",
      data: {
        email: email,
        password: password,
        name: companyName,
        phone: phone,
        address: address,
      },
    })
      .then(res => {
        if (res.data.responseCode === 200) {
          alert("Success Add User");
          window.location.reload();
        } else {
          alert(res.data.messages);
        }
      })
      .catch(e => {
        console.log(e);
        alert(e.response.data.messages);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "space-around",
      }}
    >
      <Typography variant="h6">Add User</Typography>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "",
            flexWrap: "wrap",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <TextField
            required
            error={companyName == "" ? true : false}
            onChange={e => setCompanyName(e.target.value)}
            style={{ marginTop: "5%", width: "100%" }}
            variant="outlined"
            size="small"
            label="Name"
          />
          <TextField
            required
            error={email == "" ? true : false}
            onChange={e => setEmail(e.target.value)}
            style={{ marginTop: "5%", width: "100%" }}
            variant="outlined"
            size="small"
            label="Email"
            type="email"
          />
          <TextField
            required
            error={password == "" ? true : false}
            onChange={e => setPassword(e.target.value)}
            style={{ marginTop: "5%", width: "100%" }}
            variant="outlined"
            size="small"
            label="Password"
            type="password"
            autoComplete="current-password"
          />
          <TextField
            required
            error={phone == "" ? true : false}
            onChange={e => setPhone(e.target.value)}
            style={{ marginTop: "5%", width: "100%" }}
            variant="outlined"
            size="small"
            label="Phone"
          />

          <TextField
            required
            error={address == "" ? true : false}
            onChange={e => setAddress(e.target.value)}
            style={{ marginTop: "5%", width: "100%" }}
            variant="outlined"
            size="small"
            label="Address"
          />
        </div>
      </div>
      <div
        style={{
          marginTop: "5%",
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={props.onclose}
          variant="outlined"
          size="small"
          color="default"
        >
          cancel
        </Button>
        <Button
          disabled={saveDisable}
          onClick={addCompany}
          variant="outlined"
          size="small"
          style={{
            marginLeft: "3%",
            backgroundColor: "#429C46",
            color: "white",
          }}
        >
          save
        </Button>
      </div>
    </div>
  );
};

export default Cards;
