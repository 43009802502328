import React from "react";
import {
  Button,
  makeStyles,
  Card,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import api from "../../URL/axiosConfig";

const Cards = props => {
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [picName, setPicName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [handphone, setHandphone] = React.useState("");
  const [urlHost, setHost] = React.useState("");
  const [urlApi, setApi] = React.useState("");
  const [saveDisable, setSaveDisable] = React.useState(true);
  React.useEffect(() => {
    //setLabelWidth(inputLabel.current.offsetWidth);
    console.log(saveDisable, "button");
    if (
      email == "" ||
      password == "" ||
      companyName == "" ||
      picName == "" ||
      phone == "" ||
      address == "" ||
      handphone == "" ||
      urlApi == ""
    ) {
      setSaveDisable(true);
    } else setSaveDisable(false);
  }, [
    email,
    password,
    companyName,
    picName,
    phone,
    address,
    handphone,
    urlApi,
  ]);

  const addCompany = e => {
    e.preventDefault();
    api({
      method: "POST",
      url: "/register/company",
      data: {
        email: email,
        password: password,
        username: username,
        company_name: companyName,
        pic_name: picName,
        phone: phone,
        address: address,
        handphone: handphone,
        role_id: 1,
        url_host: urlHost,
        url_api: urlApi,
      },
    })
      .then(res => {
        if (res.data.responseCode === 200) {
          alert(res.data.messages);
          window.location.reload();
        } else {
          alert(res.data.messages);
        }
      })
      .catch(e => {
        console.log(e);
        alert(e.response.data.messages);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "space-around",
      }}
    >
      <Typography variant="h6">Add Company</Typography>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "",
            flexWrap: "wrap",
            justifyContent: "space-around",
            width: "45%",
          }}
        >
          <TextField
            required
            error={companyName == "" ? true : false}
            onChange={e => setCompanyName(e.target.value)}
            style={{ marginTop: "5%", width: "100%" }}
            variant="outlined"
            size="small"
            label="Company Name"
          />
          <TextField
            required
            error={urlApi == "" ? true : false}
            onChange={e => setApi(e.target.value)}
            style={{ marginTop: "5%", width: "100%" }}
            variant="outlined"
            size="small"
            label="URL_API"
          />
          {/* <TextField onChange={(e) => setUsername(e.target.value)} style={{marginTop:'5%',width:'100%'}} variant='outlined' size='small' label='Username'/> */}
          <TextField
            required
            error={email == "" ? true : false}
            onChange={e => setEmail(e.target.value)}
            style={{ marginTop: "5%", width: "100%" }}
            variant="outlined"
            size="small"
            label="Email"
            type="email"
          />
          <TextField
            required
            error={password == "" ? true : false}
            onChange={e => setPassword(e.target.value)}
            style={{ marginTop: "5%", width: "100%" }}
            variant="outlined"
            size="small"
            label="Password"
            type="password"
            autoComplete="current-password"
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            justifyContent: "space-around",
            width: "45%",
          }}
        >
          <TextField
            required
            error={picName == "" ? true : false}
            onChange={e => setPicName(e.target.value)}
            style={{ marginTop: "5%", width: "100%" }}
            variant="outlined"
            size="small"
            label="PIC Name"
          />
          <TextField
            required
            error={address == "" ? true : false}
            onChange={e => setAddress(e.target.value)}
            style={{ marginTop: "5%", width: "100%" }}
            variant="outlined"
            size="small"
            label="Address"
          />
          <TextField
            required
            error={phone == "" ? true : false}
            onChange={e => setPhone(e.target.value)}
            style={{ marginTop: "5%", width: "100%" }}
            variant="outlined"
            size="small"
            label="Phone"
          />
          <TextField
            required
            error={handphone == "" ? true : false}
            onChange={e => setHandphone(e.target.value)}
            style={{ marginTop: "5%", width: "100%" }}
            variant="outlined"
            size="small"
            label="Handphone"
          />
        </div>
        {/* <div  style={{display:'flex', flexDirection:'column', flexWrap:'wrap', justifyContent:'space-around', width:'30%'}}>
            <TextField onChange={(e) => setHost(e.target.value)} style={{marginTop:'5%',width:'100%'}} variant='outlined' size='small' label='URL_HOST'/>
            <TextField onChange={(e) => setApi(e.target.value)} style={{marginTop:'5%',width:'100%'}} variant='outlined' size='small' label='URL_API'/>
            <TextField style={{marginTop:'5%',width:'100%',opacity:'0'}} variant='outlined' size='small' label='Tenant Service Id'/>
            <TextField style={{marginTop:'5%',width:'100%',opacity:'0'}} variant='outlined' size='small' label='Totalizer'/>
            </div> */}
      </div>
      <div
        style={{
          marginTop: "5%",
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={props.onclose}
          variant="outlined"
          size="small"
          color="default"
        >
          cancel
        </Button>
        <Button
          disabled={saveDisable}
          onClick={addCompany}
          variant="outlined"
          size="small"
          style={{
            marginLeft: "3%",
            backgroundColor: "#429C46",
            color: "white",
          }}
        >
          save
        </Button>
      </div>
    </div>
  );
};

export default Cards;
