import React from "react";
import { withRouter } from "react-router-dom";
import AddRtu from "./AddRtu";
import EditRtu from "./EditRtu";
import {
  Grid,
  Modal,
  Backdrop,
  Fade,
  makeStyles,
  Button,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import api from "../../URL/axiosConfig";
import CircularProgress from "@material-ui/core/CircularProgress";
// components
import PageTitle from "../../components/PageTitle";
import { EditOutlined } from "@material-ui/icons";
import ConfirmationModal from "../../components/Confimation/ConfirmationModal";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "80%",
  },
  paper2: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "40%",
  },
}));
function Rtu(props) {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [head, setHead] = React.useState([]);
  const [isBusy, setBusy] = React.useState(true);
  const [editData, setEditData] = React.useState(undefined);
  const [isAdd, setIsAdd] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [id, setId] = React.useState(0);
  const [open2, setOpen2] = React.useState(false);

  React.useEffect(() => {
    if (reload) {
      setBusy(false);
      const fetchData = async () => {
        const res = await api("/rtu/appname?is_water_level=0");
        const datas = res.data.messages ? res.data.messages : [];
        const resultArray = datas.map((key, idx) => ({
          header: key.header,
          detail: key.detail,
          brand: key.brand,
          billing:
            key.weiots_billing_rtus.length !== 0
              ? key.weiots_billing_rtus.map(keys => keys.description).toString()
              : "",
          action: (
            <Button
              variant="outlined"
              onClick={() => {
                setEditData(key);
                setIsAdd(false);
                setOpen(!open);
              }}
              color="default"
              startIcon={<EditOutlined />}
            >
              Edit
            </Button>
          ),
          action2: (
            <Button
              variant="outlined"
              onClick={() => {
                console.log(key.id);
                setId(key.id);
                setOpen2(true);
              }}
              color="default"
              startIcon={<EditOutlined />}
            >
              DELETE
            </Button>
          ),
        }));
        var bodies = [];
        var bodyItems = [];
        resultArray.map(val => {
          Object.keys(val).forEach(function(val2) {
            bodyItems.push(val[val2]);
          });
          bodies.push(bodyItems);
          bodyItems = [];
          return setData(bodies);
        });
        var headItems = [
          "devEUI",
          "Display Name",
          "App Name",
          "Fields Billing",
          "",
          "",
        ];
        setHead(headItems);
        setReload(false);
      };
      fetchData();
    }
  }, [reload]);

  React.useEffect(() => {
    setReload(true);
  }, []);

  const classes = useStyles();
  const handleModal = () => {
    setOpen(!open);
    setIsAdd(true);
  };

  const SetDeleteRtu = id1 => {
    console.log(id1);
    api({
      url: "/delete/master/rtu/" + id1,
      method: "POST",
    })
      .then(ok => {
        console.log(ok);
        window.location.reload();
      })
      .catch(err => {
        console.log(err);
        window.location.reload();
      });
  };
  return (
    <>
      <ConfirmationModal
        open={open2}
        setOpen={setOpen2}
        title="Are you sure to delete this Master Rtu?"
        desc="Master Rtu deleted can't be restore"
        onCancel={() => console.log("cancel")}
        onConfirm={() => SetDeleteRtu(id)}
      />
      <PageTitle title="RTU" button="Add RTU" modal={handleModal} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            data={data}
            columns={head}
            options={{
              filter: true,
              selectableRows: false,
              filterType: "dropdown",
              responsive: "stacked",
              rowsPerPage: 10,
              textLabels: {
                body: {
                  noMatch: isBusy ? (
                    <div>
                      <CircularProgress disableShrink />
                    </div>
                  ) : (
                    "Sorry, there is no matching data to display"
                  ),
                },
              },
              onSearchOpen: () => {
                setBusy(false);
              },
              onsearchClose: () => {
                setBusy(true);
              },
            }}
          />
        </Grid>
      </Grid>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper2}>
            {isAdd ? (
              <AddRtu onclose={handleModal} />
            ) : (
              <EditRtu
                onClose={handleModal}
                stateModal={open}
                data={editData}
                setReload={setReload}
              />
            )}
          </div>
        </Fade>
      </Modal>
    </>
  );
}

export default withRouter(Rtu);
