import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid, Modal, Backdrop, Fade, makeStyles } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import api from "../../URL/axiosConfig";
// components
import PageTitle from "../../components/PageTitle";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "80%",
  },
  paper2: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "40%",
  },
}));

const WeCurrencyUsd = (value = Number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
};

const WeCurrencyIdr = (value = Number) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(value);
};

function SaaSBilling(props) {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [head, setHead] = React.useState([]);
  const [isBusy, setBusy] = React.useState(true);
  React.useEffect(() => {
    setBusy(false);
    const fetchData = async () => {
      const res = await api("/v2/list/billing");
      console.log("data", res.data);
      const datas = res.data.data ? res.data.data : [];
      const resultArray = datas.map(key => ({
        billing_type: key.billing_type,
        createdAt: moment(key.createdAt).format("DD-MM-YYYY"),
        due_date: moment(key.due_date).format("DD-MM-YYYY"),
        billing_total:
          key.bill_curency == "USD"
            ? key.bill_curency +
              " " +
              WeCurrencyUsd(key.bill_amount).split("$")[1]
            : key.bill_curency + WeCurrencyIdr(key.bill_amount).split("Rp")[1],
        bill_periode: moment(key.bill_periode).format("MM-YYYY"),
        status: key.status,
      }));
      var bodies = [];
      var bodyItems = [];
      resultArray.map(val => {
        Object.keys(val).forEach(function(val2) {
          bodyItems.push(val[val2]);
          console.log(bodyItems);
        });
        bodies.push(bodyItems);
        bodyItems = [];
        console.log(bodies);
        return setData(bodies);
      });
      console.log(datas);
      var headItems = [
        "Billing Type",
        "Created at",
        "Billing Due Date",
        "Billing Total",
        "Billing Period",
        "Status",
      ];
      setHead(headItems);
      //setData(resultArray)
    };
    fetchData();
  }, []);
  const classes = useStyles();
  const handleModal = () => {
    setOpen(!open);
    console.log(open);
  };
  return (
    <>
      <PageTitle title="SaaS Billing" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            data={data}
            columns={head}
            options={{
              filter: true,
              selectableRows: false,
              filterType: "dropdown",
              responsive: "stacked",
              rowsPerPage: 10,
              textLabels: {
                body: {
                  noMatch: isBusy ? (
                    <div>
                      <CircularProgress disableShrink />
                    </div>
                  ) : (
                    "Sorry, there is no matching data to display"
                  ),
                },
              },
              onSearchOpen: () => {
                setBusy(false);
              },
              onsearchClose: () => {
                setBusy(true);
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default withRouter(SaaSBilling);
