import React from "react";
import { withRouter } from "react-router-dom";
import AddUsers from "./AddUsers";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid, Modal, Backdrop, Fade, makeStyles } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import api from "../../URL/axiosConfig";
// components
import PageTitle from "../../components/PageTitle";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "80%",
  },
  paper2: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "60%",
  },
}));

function Users(props) {
  const [open, setOpen] = React.useState(false);
  const [isBusy, setBusy] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [head, setHead] = React.useState([]);
  console.log(data, "aaaaaaaaaaaaaaaaaaaaaaa");
  React.useEffect(() => {
    setBusy(false);
    api("/v2/list/user")
      .then(res => {
        const companies = res.data.data ? res.data.data : [];
        const resultArray = companies.map(key => ({
          name: key.name,
          email: key.email,
          phone: key.phone,
          address: key.address,
          createdAt: moment(key.createdAt).format("DD-MM-YYYY HH:mm:ss"),
        }));
        console.log(companies);
        var bodies = [];
        var bodyItems = [];
        resultArray.map(val => {
          Object.keys(val).forEach(function(val2) {
            bodyItems.push(val[val2]);
            console.log(bodyItems);
          });
          bodies.push(bodyItems);
          bodyItems = [];
          console.log(bodies);
          setData(bodies);
        });
        var headItems = ["Name", "Email", "Phone", "Address", "Created At"];
        setHead(headItems);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const classes = useStyles();
  const handleModal = () => {
    setOpen(!open);
    console.log(open);
  };
  return (
    <>
      <PageTitle title="Users" button="Add User" modal={handleModal} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            data={data}
            columns={head}
            options={{
              filter: true,
              selectableRows: false,
              filterType: "dropdown",
              responsive: "stacked",
              rowsPerPage: 10,

              textLabels: {
                body: {
                  noMatch: isBusy ? (
                    <div>
                      <CircularProgress disableShrink />
                    </div>
                  ) : (
                    "Sorry, there is no matching data to display"
                  ),
                },
              },
              onSearchOpen: () => {
                setBusy(false);
              },
              onsearchClose: () => {
                setBusy(true);
              },
            }}
          />
        </Grid>
        {/* <Grid item xs={12}>
                    <Widget title="Material-UI Table" upperTitle noBodyPadding>
                        <Table data={mock.table} />
                    </Widget>
                </Grid> */}
      </Grid>

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper2}>
            <AddUsers onclose={handleModal} />
          </div>
        </Fade>
      </Modal>
    </>
  );
}

export default withRouter(Users);
