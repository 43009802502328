import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  List,
  Card,
  InputLabel,
  CardHeader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Button,
  Divider,
  Select,
  FormControl,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import api from "../../URL/axiosConfig";
import axios from "axios";
// components
import PageTitle from "../../components/PageTitle";
import Widget from "../../components/Widget";
import Table from "../dashboard/components/Table/Table";

// data
import mock from "../dashboard/mock";

const useStyles = makeStyles(theme => ({
  root: {
    margin: "auto",
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 400,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const resultFilter = (firstArray, secondArray) => {
  return firstArray.filter(
    firstArrayItem =>
      !secondArray.some(
        secondArrayItem =>
          firstArrayItem.type_name === secondArrayItem.type_name,
      ),
  );
};

function MappingCompany(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [rawLeft, setRawLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const [url, setUrl] = React.useState("");
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const [company, setCompany] = React.useState([]);

  React.useEffect(() => {
    const fetchType = async () => {
      const res = await api("/list/type");
      console.log("data1", res.data);
      const data = res.data.list_type ? res.data.list_type : [];
      const resulte = data.map(key => ({
        type_id: key.id,
        type_name: key.type_name,
      }));
      console.log(resulte);
      setRawLeft(resulte);
    };
    const fetchCompany = async () => {
      const res = await api("/list/company");
      console.log("data2", res.data);
      const data = res.data.list_company ? res.data.list_company : [];
      setCompany(data);
    };
    fetchType();
    fetchCompany();
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = items => intersection(checked, items).length;

  const handleCompany = e => {
    setUrl(e.target.value);
    if (e.target.value !== "") {
      //console.log(e.target.value)
      // axios({
      //   method: "GET",
      //   url: "url=" + e.target.value + "/list/type",
      // })
      api("/company/list/type?url=" + e.target.value + "/list/type")
        .then(res => {
          //console.log(res.data);
          if (res.data.responseCode === 200) {
            const dataRight = res.data.list_type ? res.data.list_type : [];
            console.log(dataRight);
            // const resultArray = dataRight.map(key => ({
            //   type_id: key.id,
            //   type_name: key.type_name,
            // }));
            console.log(dataRight);
            setRight(dataRight);
            if (right !== []) {
              const results = rawLeft.filter(
                ({ type_name: id1 }) =>
                  !dataRight.some(({ type_name: id2 }) => id2 === id1),
              );
              console.log("dataleft", results);
              setLeft(results);
              // console.log(/results);
            }
          } else {
            alert("Ada kesalahan jaringan");
          }
        })
        .catch(e => {
          alert(e.response.data.messages);
          //console.log(e.response);
        });
    } else {
      setRight([]);
      setLeft([]);
    }
  };
  const handleToggleAll = items => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    const dataToRight = right;
    leftChecked.map(val => {
      dataToRight.push(val);
    });
    // console.log(
    //   "left to rigth",
    //   dataToRight,
    //   " ",
    //   leftChecked,
    //   " ",
    //   right.concat(leftChecked),
    // );
    // setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    api({
      method: "POST",
      url: "/allocation/type",
      data: {
        url_host: url,
        data: dataToRight,
      },
    })
      .then(res => {
        console.log(res.data);
        if (res.data.responseCode === 200) {
          alert(res.data.messages);
        } else {
          alert(res.data.messages);
        }
      })
      .catch(e => {
        console.log(e);
      });
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    api({
      method: "POST",
      url: "/allocation/type",
      data: {
        url_host: url,
        data: not(right, rightChecked),
      },
    })
      .then(res => {
        console.log(res.data);
        if (res.data.responseCode === 200) {
          alert(res.data.messages);
        } else {
          alert(res.data.messages);
        }
      })
      .catch(e => {
        console.log(e);
      });
    setChecked(not(checked, rightChecked));
  };
  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value, index) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={index}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value.type_name}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <>
      <PageTitle title="Allocation Node Type" />
      <FormControl
        alignItems="center"
        style={{ marginBottom: "5%", width: "40%" }}
        size="small"
        variant="outlined"
      >
        <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple">
          Company
        </InputLabel>
        <Select
          native
          value={url}
          onChange={e => handleCompany(e)}
          labelWidth={labelWidth}
        >
          <option value="" />
          {company.map((val, index) => {
            return (
              <option key={index} value={val.url_api}>
                {val.company_name}
              </option>
            );
          })}
        </Select>
      </FormControl>
      <Grid
        container
        spacing={2}
        justify="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item xs={4}>
          {customList("Global Node Type", left)}
        </Grid>
        <Grid item xs={2}>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          {customList("Company Node Type", right)}
        </Grid>
      </Grid>
    </>
  );
}

export default withRouter(MappingCompany);
