import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Business as BusinessIcon,
  Home as HomeIcon,
  Tram as NodeIcon,
  BatteryCharging20 as RtuIcon,
  BatteryCharging30 as RtuIcon2,
  Category as AllocIcon,
  TrackChanges as GatewayIcon,
  SpeakerGroup as NodeTypeIcon,
  CellWifi as MiddlewareIcon,
  ArrowBack as ArrowBackIcon,
  Assignment,
  People,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import api from "../../URL/axiosConfig";

const structure = [
  // { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
  {
    id: 1,
    label: "Master Company",
    link: "/app/company",
    icon: <BusinessIcon />,
  },
  // { id: 2, label: "Tables", link: "/app/tables", icon: <TableIcon /> },
  // {
  //   id: 2,
  //   label: "Master Gateway",
  //   link: "/app/type-node",
  //   icon: <NodeTypeIcon />,
  // },
  {
    id: 3,
    label: "Master Node Type",
    link: "/app/type-node",
    icon: <NodeTypeIcon />,
  },
  {
    id: 4,
    label: "Allocation Node Type",
    link: "/app/mappingCompany",
    icon: <AllocIcon />,
  },
  {
    id: 5,
    label: "Master RTU",
    link: "/app/rtu",
    icon: <RtuIcon />,
  },
  {
    id: 13,
    label: "RTU Water Level",
    link: "/app/water-level",
    icon: <RtuIcon2 />,
  },
  // {
  //   id: 6,
  //   label: "Master Middleware",
  //   link: "/app/middleware",
  //   icon: <MiddlewareIcon />,
  // },
  {
    id: 7,
    label: "Master Node",
    link: "/app/node",
    icon: <NodeIcon />,
  },
  {
    id: 8,
    label: "Master Gateway",
    link: "/app/gateway",
    icon: <GatewayIcon />,
  },
  {
    id: 14,
    label: "SaaS Billing",
    link: "/app/saas-billing",
    icon: <Assignment />,
  },
  {
    id: 15,
    label: "Users",
    link: "/app/users",
    icon: <People />,
  },
  // {
  //   id: 6,
  //   label: "UI Elements",
  //   link: "/app/ui",
  //   icon: <UIElementsIcon />,
  //   children: [
  //     { label: "Icons", link: "/app/ui/icons" },
  //     { label: "Charts", link: "/app/ui/charts" },
  //     { label: "Maps", link: "/app/ui/maps" },
  //   ],
  // },
  // { id: 10, type: "divider" },
  // { id: 11, type: "title", label: "PROJECTS" },
  // {
  //   id: 12,
  //   label: "My recent",
  //   link: "",
  //   icon: <Dot size="large" color="warning" />,
  // },
  // {
  //   id: 13,
  //   label: "Starred",
  //   link: "",
  //   icon: <Dot size="large" color="primary" />,
  // },
  // {
  //   id: 14,
  //   label: "Background",
  //   link: "",
  //   icon: <Dot size="large" color="secondary" />,
  // },
];

const structure2 = [
  {
    id: 1,
    label: "Master Company",
    link: "/app/company",
    icon: <BusinessIcon />,
  },
  {
    id: 3,
    label: "Master Node Type",
    link: "/app/type-node",
    icon: <NodeTypeIcon />,
  },
  {
    id: 4,
    label: "Allocation Node Type",
    link: "/app/mappingCompany",
    icon: <AllocIcon />,
  },
  {
    id: 7,
    label: "Master Node",
    link: "/app/node",
    icon: <NodeIcon />,
  },
  {
    id: 8,
    label: "Master Gateway",
    link: "/app/gateway",
    icon: <GatewayIcon />,
  },
  {
    id: 14,
    label: "SaaS Billing",
    link: "/app/saas-billing",
    icon: <Assignment />,
  },
  {
    id: 15,
    label: "Users",
    link: "/app/users",
    icon: <People />,
  },
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  const [isRtu, setIsRtu] = React.useState(false);

  React.useEffect(async () => {
    const arr = ["RTU", "RTU WATER LEVEL", "RTU DLMS"];
    const res = await api("/list/type");
    try {
      // substringsArray.some(substring=>yourBigString.includes(substring))
      res.data.list_type.map((val, idx) => {
        // console.log(
        //   arr.some(w => val.type_name.includes(w)),
        //   "SSS",
        // );
        if (arr.some(w => val.type_name.includes(w))) {
          setIsRtu(true);
          return;
        }
      });
    } catch (error) {
      console.log(error, "ERR");
      setIsRtu(false);
    }
  }, []);

  // console.log(isRtu, "ISRTU");

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      {isRtu ? (
        <List className={classes.sidebarList}>
          {structure.map(link => (
            <SidebarLink
              key={link.id}
              location={location}
              isSidebarOpened={isSidebarOpened}
              {...link}
            />
          ))}
        </List>
      ) : (
        <List className={classes.sidebarList}>
          {structure2.map(link => (
            <SidebarLink
              key={link.id}
              location={location}
              isSidebarOpened={isSidebarOpened}
              {...link}
            />
          ))}
        </List>
      )}
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
